// Custom button variants
//

@mixin button-variant-custom($clr, $bg, $bd, $clr-hover, $bg-hover, $bd-hover) {
  color: $clr;
  background-color: $bg;
  border-color: $bd;

  &:focus,
  &.focus,
  &:hover,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $clr-hover;
    background-color: $bg-hover;
    border-color: $bd-hover;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    box-shadow: $shadow-area-sm;

    // TODO Optimize css export
    &:hover,
    &:focus,
    &.focus {
      color: $clr-hover;
      background-color: $bg-hover;
      border-color: $bd-hover;
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    pointer-events: none;
    opacity: .5;
  }

  .badge {
    color: $bg;
    background-color: $clr;
  }
}


// Custom button variants
@mixin btn-variant($clr, $bg, $bd, $clr-hover, $bg-hover, $bd-hover) {
  &,
  &:focus {
    color: $clr;
    background-color: $bg;
    border-color: $bd;
  }
  
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:hover,
  &:active,
  &.active {
    color: $clr-hover;
    background-color: $bg-hover;
    border-color: $bd-hover;
  }
  
  &.button-ujarak {
    &::before {
      background: $bg-hover;
    }
  }
}

// Button sizes
@mixin btn-size($padding-vertical, $padding-horizontal, $font-size, $line-height) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
}

// Reset Button
@mixin reset-button() {
  background: none;
  border: none;
  display: inline-block;
  padding: 0;
  outline: none;
  outline-offset: 0;
  cursor: pointer;
  -webkit-appearance: none;
  
  &::-moz-focus-inner {
    border: none;
    padding: 0;
  }
}
