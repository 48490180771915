//
// Box member
// --------------------------------------------------


// Base styles
// --------------------------------------------------

// Box member type 1
// -------------------------

.box-member {
  position: relative;
  display: inline-block;

  img {
    display: inline-block;
  }
}

// hover from 1200
@include media-breakpoint-up(xl) {
  .box-member .box-member-wrap {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 81%;
    z-index: 2;
    transition: .3s;

    // Box Member Caption
    .box-member-caption {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      margin-top: 0;
      width: 100%;
      height: 100%;
      padding: $box-member-caption-padding;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-transform: uppercase;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      .list-inline {
        li {
          transform: translateY(40px);
          transition: .35s all ease;
          opacity: 0;

          &:nth-child(2) {
            transition-delay: .1s;
          }

          &:last-child {
            transition-delay: .2s;
          }
        }
      }
    }

    &:hover {
      background: $box-member-hover-background;

      .list-inline li {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

// Member block type 3
// -------------------------

.member-block-type-3 {
  margin-left: auto;
  margin-right: auto;
  max-width: $box-member-type-3-max-width;
  box-shadow: $box-member-type-3-shadow;
  border-radius: $box-member-type-3-border-radius;

  .member-block-body{
    padding: $box-member-type-3-body-padding
  }

  .member-block-footer{
    .btn{
      font-size: $box-member-type-3-footer-btn-size;
      border-color: transparent;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  .btn-group{
    display: block;
    margin-left: -4px;
    margin-right: -4px;
    .btn{
      @include button-variant-custom($gray-darker, $white, $gray, $white, $gray-darker, $gray-darker);
      float:  none;
      width: 33.5%;
      .icon{
        font-size: $box-member-type-3-footer-btn-icon-size;
        line-height: $box-member-type-3-footer-btn-icon-size;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-left: 0;
  }
}

// Member block type 5
// -------------------------

.member-block-type-5 {

  img{
    border: 10px solid $white;
    border-radius: 5px;
    box-shadow: $shadow-area-md;
  }

  .member-block-body {
    padding: $box-member-type-5-padding;
    .contact-info {
      li {
        .icon {
          padding-right: $box-member-type-5-list-item-icon-offset-right;
        }
        & + li {
          margin-top: $box-member-type-5-list-item-offset-top;
        }
      }
    }

    @include media-breakpoint-up(md) {
      .contact-info li + li {
        margin-top: $box-member-type-5-list-item-sm-offset-top;
      }
    }
    @include media-breakpoint-up(lg) {
      padding: $box-member-type-5-md-padding;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top:-256px!important;
  }
}