//
// Custom Progress Bars
// --------------------------------------------------

// Linear Progress Bars
// --------------------------------------------------

.progress-linear {
	position: relative;
	display: flex;
	flex-direction: column;
	
	.progress-bar-linear-wrap, .progress-bar-linear {
		height: 100%;
		border-radius: $progress-bar-linear-border-radius;
	}
	
	.progress-bar-linear-wrap {
		margin-top: 8px;
		background: $progress-bar-linear-empty-background;
	}
	
	.progress-bar-linear {
		width: 0;
		height: $progress-linear-height;
		@include transition(.5s all ease);
	}
	
	h6 {
		text-align: left;
	}
	
	.progress-value {
		&:after {
			content: "%";
		}
	}
}

// Circle Progress Bars
// --------------------------------------------------

.progress-bar-circle {
	display: inline-block;
	position: relative;
	text-align: center;
	line-height: 1.2;
	
	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		width: $progress-bar-circle-width;
		height: $progress-bar-circle-height;
		
		content: '';
		transform: translate(-50%, -50%);
		border-radius: 50%;
		border: 1px solid $gray-light;
	}
	
	canvas {
		vertical-align: middle;
	}
	
	span {
		position: absolute;
		top: 48%;
		left: 50%;
		font-size: 18px;
		line-height: 24px;
		font-family: $font-family-accent;
		color: $gray-darker;
		transform: translate(-50%, -50%);
		
		&:after {
			content: $progress-bar-circle-icon;
		}
	}
}

// Change bootstrap progress
// --------------------------------------------------

.progress {
	height: $progess-height;
}

.progress-bar {
	font-weight: $progress-font-weight;
	font-size: $progress-font-size;
	line-height: $progess-height;
	box-shadow: none;
}

// Add one more variation


/** @section Progress Circle */


.progress-circle {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	line-height: 1.2;
}

.progress-circle-block {
	position: relative;
}

.progress-circle-bg {
	fill: transparent;
	stroke: #f5f5f5;
	stroke-width: 8px;
}

.progress-circle-fg {
	fill: transparent;
	stroke: #f2e661;
	stroke-width: 8px;
}

.progress-circle-counter {
	position: absolute;
	top: 50%;
	left: 51%;
	font-size: 18px;
	line-height: 18px;
	transform: translate(-50%, -50%);
	color: #434445;
	
	&::after {
		content: "%";
	}
}

.progress-circle-title {
	font-size: 22px;
	font-weight: 700;
	text-transform: uppercase;
	color: #868686;
}

* + .progress-bar-circle-title {
	margin-top: 12px;
}


.progress-circle-block {
	position: relative;
	
	&:after {
		content: '';
		position: absolute;
		display: block;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 85px;
		height: 85px;
		border: 1px solid #e5e5e5;
		border-radius: 50%;
		z-index: 2;
	}
}