/**
*    This element is created inside your target element
*    It is used so that your own element will not need to be altered
**/
.time_circles {
    position: relative;
    width: 100%;
    height: 100%;
}

/**
*    This is all the elements used to house all text used
* in time circles
**/
.time_circles > div {
    position: absolute;
    text-align: center;
    font-family: $font-family-accent;
    top: 50% !important;
    transform: translateY(-63%) !important;
    -webkit-transform: translateY(-63%) !important;
}

@include media-breakpoint-up(xl) {
    #DateCountdown {
        width: 100%;
    }
}

/**
*    Titles (Days, Hours, etc)
**/
.time_circles > div > h4 {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 18px !important;
    color: $white;
    font-weight: 700;
    font-family: $font-family-base;
    position: absolute;
    bottom: -80px;
    left: 0;
    right: 0;

    @media (min-width: 480px) {
        bottom: -80px;
    }
    @media (min-width: 768px) {
        bottom: -95px;
        font-size: 22px !important;
    }
    @include media-breakpoint-up(lg) {
        bottom: -95px;
    }
    & + * {
        margin-top: 0;
    }
}

/**
*    Time numbers, ie: 12
**/
.time_circles > div > span {
    display: block;
    font-size: 16px !important;
    text-align: center;
    font-family: $font-family-accent;

    @include media-breakpoint-up(md) {
      font-size: 18px !important;
    }

    @include media-breakpoint-up(xl) {

    }
}


.countdown-wrapper {
    margin: 0 auto;
    max-width: 260px;
    @include media-breakpoint-up(sm) {
        max-width: 625px;
    }
}