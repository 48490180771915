//
// Toolbar Shop
// --------------------------------------------------


// Base styles
// --------------------------------------------------

.toolbar-shop {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background: $toolbar-shop-bg;

  &-icon {
    display: inline-block;
    height: $toolbar-shop-icon-height;
    padding: $toolbar-shop-icon-padding;
    line-height: $toolbar-shop-icon-line-height;
    border-right: $toolbar-shop-icon-border-right;
    transition: .3s all ease;

    span {
      vertical-align: middle;
    }

    &.active, &:hover {
      color: $toolbar-shop-icon-color-active;
    }
  }

  &-sorter{
    display: none;
    white-space: nowrap;
  }

  &-pager{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > *{
      display: inline-block;
      vertical-align: middle;
    }

    label,select {
      display: inline-block;
      vertical-align: middle;
    }

    label{
      margin-right: 18px;
    }

    .select2-container{
      width: 118px!important;
    }

    .select2-container--bootstrap .select2-selection{
      border-radius: 0;
      border: none;
      border-left: 1px solid $gray;
    }

    .select2-selection__arrow{
      color: $gray-dark;
    }

    .select2-container--bootstrap .select2-selection--single .select2-selection__rendered{
      color: $gray-darker;
    }


    .select2-container--bootstrap{
      display: inline-block;
    }

    label{
      margin-bottom: 0;
    }

  }

  .toolbar-shop-current{
    margin-left: 10px;
  }

  @media (min-width: $screen-sm)  {
    justify-content: space-between;

    &-sorter{
      display: block;
    }

    &-pager{
      display: block;
      width: auto;
    }

    .toolbar-shop-current{
      margin-left: 0;
      margin-right: 6px;
    }
  }
}
