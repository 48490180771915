/** @section Text styling */

// Text styles
.text-italic {
	font-style: italic;
}

.text-normal {
	font-style: normal;
}

// Text decoration
.text-underline {
	text-decoration: underline;
}

.text-strike {
	text-decoration: line-through;
}

// Text weight
.font-weight-thin {
	font-weight: $font-weight-thin;
}

.font-weight-extra-light {
	font-weight: $font-weight-extra-light;
}

.font-weight-light {
	font-weight: $font-weight-light;
}

.font-weight-regular {
	font-weight: $font-weight-regular;
}

.font-weight-medium {
	font-weight: $font-weight-medium;
}

.font-weight-semi-bold {
	font-weight: $font-weight-semi-bold;
}

.font-weight-bold {
	font-weight: $font-weight-bold;
}

.font-weight-extra-bold {
	font-weight: $font-weight-extra-bold;
}

.font-weight-black {
	font-weight: $font-weight-black;
}

// Text spacing
.text-spacing-0 {
	letter-spacing: 0;
}

.text-primary {
	color: $primary !important;
}


// Text weight
//
.text-thin {
	font-weight: 100;
}

.text-light {
	font-weight: 300;
}

.text-regular {
	font-weight: 400;
}

.text-medium {
	font-weight: 500;
}

.text-sbold {
	font-weight: 600;
}

.text-bold, strong {
	font-weight: 700;
}

.text-ubold {
	font-weight: 900;
}

// Text spacing
//
.text-spacing-0 {
	letter-spacing: 0;
}

.text-spacing-20 {
	letter-spacing: 0.02em;
}

.text-spacing-40 {
	letter-spacing: 0.04em;
}

.text-spacing-60 {
	letter-spacing: 0.06em;
}

.text-spacing-120 {
	letter-spacing: 0.12em;
}


