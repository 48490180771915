/*
*
* Preloader
*/

//
// Base styles
//
.preloader {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	background: $white;
	transition: .3s all ease;

	&.loaded {
		opacity: 0;
		visibility: hidden;
	}
}

.preloader-body {
	text-align: center;

	p {
		position: relative;
		right: -8px;
	}
}

//
// Custom Styles
// 
.cssload-container {
	width: 100%;
	height: 49px;
	text-align: center;
}

.cssload-double-torus {
	width: 49px;
	height: 49px;
	margin: 0 auto;
	border: 4px double;
	border-radius: 50%;
	border-color: transparent rgba(42,148,201,0.9) rgba(42,148,201,0.9);
	animation: cssload-spin 780ms infinite linear;
	-o-animation: cssload-spin 780ms infinite linear;
	-ms-animation: cssload-spin 780ms infinite linear;
	-webkit-animation: cssload-spin 780ms infinite linear;
	-moz-animation: cssload-spin 780ms infinite linear;
}



@keyframes cssload-spin {
	100%{ transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes cssload-spin {
	100%{ -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@-ms-keyframes cssload-spin {
	100%{ -ms-transform: rotate(360deg); transform: rotate(360deg); }
}

@-webkit-keyframes cssload-spin {
	100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes cssload-spin {
	100%{ -moz-transform: rotate(360deg); transform: rotate(360deg); }
}