//
// Formstone Stepper Plugin
// --------------------------------------------------

.stepper{
  position: relative;
  display: inline-block;
  max-width: 70px;
  width: 70px;
  margin-bottom: $form-wrap-margin-bottom;

  input[type="number"]{
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    -moz-appearance: textfield;
  }

  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &-arrow{
    position: absolute;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-family: $icon-font-material-design;
    cursor: pointer;
    color: $stepper-arrow-color;
    transition: .3s all ease;

    &:hover{
      color: $stepper-arrow-color-hover;
    }

    &.up{
      right: 0;
      text-align: left;

      &:before{
        content: $stepper-arrow-icon-up;
      }
    }

    &.down{
      left: 0;
      text-align: right;

      &:before{
        content: $stepper-arrow-icon-down;
      }
    }
  }

  &.disabled{
    .stepper-arrow{
      pointer-events: none;
      opacity: .5;
    }
  }
}

.stepper-type-2 .stepper{
  display: inline-block;
  margin-bottom: 0;
  width: auto;
  min-width: 20px;

  input[type="number"]{
    padding: 20px 0;
    text-align: center;
    -moz-appearance: textfield;
    border: none;
    height: 60px;
    font-size: 17px;
  }

  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &-arrow{
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 20px;
    font-size: 16px;
    font-family: $stepper-type-2-arrow-icon-font;
    color: $stepper-type-2-arrow-color;
    text-align: center;

    &:hover{
      color: $stepper-type-2-arrow-color-hover;
    }

    &.up{
      top: 0;
      margin-top: 0;

      &:before{
        content: $stepper-type-2-arrow-icon-up;
      }
    }

    &.down{
      top: 100%;
      margin-top: -15px;

      &:before{
        content: $stepper-type-2-arrow-icon-down;
      }
    }
  }

}