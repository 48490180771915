//
// Mixins
// --------------------------------------------------

// Clearfix
@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

// Disable padding - top on two similar backgrounds
@mixin bg-behaviour($bg-color) {
	background-color: $bg-color;

	&:not([style*="background-"]) + &:not([style*="background-"]) { padding-top: 0; }
}

// Grid related values
@mixin make-grid-parameter($parameter, $offset, $container, $col) {
	@if $offset > 0 {
		#{$parameter}: calc(50% - #{$container} / 2 + #{$offset} + (#{$container} / #{$grid-columns}) * #{$col});
	} @else if ($offset < 0) {
		#{$parameter}: calc(50% - #{$container} / 2 - #{-$offset} + (#{$container} / #{$grid-columns}) * #{$col});
	} @else {
		#{$parameter}: calc(50% - #{$container} / 2 + (#{$container} / #{$grid-columns}) * #{$col});
	}
}

// Link universal mixin
@mixin link($default-clr, $hover-color) {
	&,
	&:active,
	&:focus {
		color: $default-clr;
	}

	&:hover {
		color: $hover-color;
	}
}


//
// Gradient background
// --------------------------------------------------

@mixin bg-variant-custom($color, $fallback: null) {
	& {
		@if (str_index($color, linear-gradient) == null){
			background: $color;
			fill: $color;
		}
		@else{
			fill: $fallback;
			background: $fallback;
			background: -moz-#{$color};
			background: -webkit-#{$color};
			background: -o-#{$color};
			background: -ms-#{$color};
			background: #{$color};
		}
		@content;
	}
}

// Divider-gradient
@mixin divider-gradient($color-1, $color-2) {
	background: rgba($color-1,1);
	background: -moz-linear-gradient(left, rgba($color-1,1) 0%, rgba($color-2,1) 50%, rgba($color-1,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba($color-1,1)), color-stop(50%, rgba($color-2,1)), color-stop(100%, rgba($color-1,1)));
	background: -webkit-linear-gradient(left, rgba($color-1,1) 0%, rgba($color-2,1) 50%, rgba($color-1,1) 100%);
	background: -o-linear-gradient(left, rgba($color-1,1) 0%, rgba($color-2,1) 50%, rgba($color-1,1) 100%);
	background: -ms-linear-gradient(left, rgba($color-1,1) 0%, rgba($color-2,1) 50%, rgba($color-1,1) 100%);
	background: linear-gradient(to right, rgba($color-1,1) 0%, rgba($color-2,1) 50%, rgba($color-1,1) 100%);
}


// [converter] $parent hack
@mixin text-emphasis-variant($parent, $color) {
	blockquote.quote #{$parent},
	#{$parent} {
		color: $color;
	}
	
	a#{$parent}:hover,
	a#{$parent}:focus {
		color: darken($color, 10%);
	}
}
