/** @section Offsets */

// Elements
p {
	* + & {
		margin-top: 15px;
	}
}


// Headings
h1,
.h1 {
	* + & {
		margin-top: 20px;

		@include media-breakpoint-up(xl) {
			margin-top: 30px;
		}
	}
}

h2,
.h2 {
	* + & {
		margin-top: 20px;
	}

	p + & {
		margin-top: 12px;
	}

	& + p {
		margin-top: 12px;
	}
}

h3,
.h3 {}

h4,
.h4 {}

h5,
.h5 {}

h6,
.h6 {}


// Classes
.button {
	* + & {
		margin-top: 30px;
	}
}


// Grid
.container {
	* + & {
		margin-top: 30px;
	}
}

.row {
	* + & {
		margin-top: 30px;
	}
}


//
// Offsets
// --------------------------------------------------

// Insets
// -------------------------

$insets: (0, 10px, 15px, 20px, 25px, 30px, 40px, 50px, 60px, 70px, 75px, 80px, 93px, 100px, 115px);

html .page {
	@include indent-responsive(inset, left, padding-left, $medias, $insets);
	@include indent-responsive(inset, right, padding-right, $medias, $insets);
}



// Elements
// -------------------------

h1 + * {
	margin-top: 43px;
}

h2 + * {
	margin-top: 33px;
}

h3 + * {
	margin-top: 27px;
}

h4 + * {
	margin-top: 11px;
}

h5 + * {
	margin-top: 9px;
}

h6 + * {
	margin-top: 14px;
}

h6 + hr {
	margin-top: 9px;
}

hr + img {
	margin-top: 8px;
}

img + p {
	margin-top: 10px;
}

p + h5 {
	margin-top: 38px;
}

h3 + .text-extra-big {
	margin-top: 42px;
}

h3 + .divider-lg {
	margin-top: 35px;
}

.icon + h5 {
	margin-top: 20px;
}

.unit + p {
	margin-top: 15px;
}

.unit + .unit {
	margin-top: 9px;
}

.inset-left-7p {
	padding-left: 7%;
}

.inset-right-7p {
	padding-right: 7%;
}

.inset-left-11p {
	padding-left: 11%;
}

.inset-right-11p {
	padding-right: 11%;
}

// Grid
// -------------------------

// Sections
// -------------------------

// Responsive
// -------------------------

$offsets: (0, 4px, 10px, 14px, 20px, 24px, 30px, 34px, 41px, 50px, 55px, 60px, 66px, 70px, 90px, 98px, 110px, 124px, 130px);

html .page {
	@include indent-responsive(offset, top, margin-top, $medias, $offsets);
	
	.offset-none {
		margin-top: 0;
		margin-bottom: 0;
	}
	
	@include media-breakpoint-up(lg) {
		.offset-md-left-negative-6 {
			margin-left: -6px;
		}
		.offset-md-right-negative-6 {
			margin-right: -6px;
		}
	}
	@include media-breakpoint-up(xl) {
		.offset-lg-top-34-negative{
			margin-top: -34px;
		}
	}
}




//
// Insets
// --------------------------------------------------

.inset-left-20 {
	padding-left: 20px;
}

.inset-left-27 {
	padding-left: 27px;
}

.inset-left-11p {
	padding-left: 11.6%;
}

.inset-right-27 {
	padding-right: 27px;
}

.inset-right-11p {
	padding-right: 11.6%;
}

.inset-right-7p {
	padding-right: 11.6%;
}

.inset-66 {
	padding-left: 66px;
	padding-right: 66px;
}

.inset-top-40 {
	padding-top: 40px;
}

.inset-top-30 {
	padding-top: 30px;
}

.inset-top-15 {
	padding-top: 15px;
}

.inset-bottom-80 {
	padding-bottom: 80px;
}

.inset-bottom-40 {
	padding-bottom: 40px;
}

.inset-none {
	padding: 0 !important;
}