/*
* Material Parallax
*/

.parallax-container {
  position: relative;
  overflow: hidden;
}

.parallax-container p {
  font-size: 18px;
  line-height: 1.33333;
}

.material-parallax {
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
  bottom: 0;
  z-index: 0;
}

.parallax-content {
  position: relative;
  z-index: 1;
}

.material-parallax img {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 101%;
  min-height: 101%;
  max-width: none;
  transform: translate3d(-50%, 0, 0);
}

.parallax-disabled {
  background-size: cover;
  background-position: center;
  
  html:not(.ie-11):not(.ios) & {
    background-attachment: fixed;
  }
}
