//
// Search Results
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.search_list {
  text-align: left;
  padding-left: 0;
  font-size: $rd-search-list-size;
  list-style-type: none;
}

.result-item {
  padding-left: $rd-result-item-padding-left;
  list-style-type: decimal;
  margin-left: $rd-result-item-offset-left;
  color: $rd-result-item-color;
  font-size: 15px;
  > * {
    color: $rd-result-item-inside-color;
  }
  p {
    font-size: $font-size-body;
  }

  + .result-item {
    margin-top: $rd-result-item-offset-top-between;
  }
  @include media-breakpoint-up(md) {
    padding-left: $rd-result-item-sm-padding-left;
  }
}

.search_title {
  color: $rd-search-title-color;
}

.match {
  font-size: $rd-search-math-size;
  text-transform: uppercase;
  letter-spacing: $rd-search-math-letter-spacing;
  color: $gray-dark;
  em {
    font-style: normal;
  }
}

.search {
  background: $rd-search-background;
  color: $rd-search-math-color;
}

.rd-navbar {
  #search-results {
    background: #fff;
    padding: 30px 25px 0 25px;
    overflow-y: auto;
    max-height: 50vh;
    box-shadow: $shadow-area-xs;

    ol {
      padding-bottom: 30px;
    }

    @media (min-height: 617px) {
      max-height: 67vh;
    }

  }

  .search_all {
    margin-top: 22px;
  }

  .search_list {
    margin-top: 20px;
  }

  .result-item + .result-item {
    margin-top: 15px;
  }
}

.rd-navbar--is-stuck {
  #rd-search-results-live {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
  }
}