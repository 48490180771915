/** @section Row spacing */

.row-0 {
	@include grid-offset(0px);
}

.row-5 {
	@include grid-offset(5px);
}

.row-15 {
	@include grid-offset(15px);
}

.row-10 {
	@include grid-offset(10px);
}

.row-20 {
	@include grid-offset(20px);
}

.row-30 {
	@include grid-offset(30px);
}

.row-40 {
	@include grid-offset(40px);
}

.row-50 {
	@include grid-offset(50px);
}


.row-xl-50{
	@include grid-offset(30px);
	@include media-breakpoint-up(xl) {
		@include grid-offset(50px);
	}
}