/** @section Tables custom */

$table-head-padding: 35px 24px;
$table-cell-padding: 17px 24px;
$table-condensed-cell-padding: 5px;

$table-bg: $white;
$table-bg-accent: $gray-200;
$table-bg-hover: $primary;
$table-bg-active: $table-bg-hover;

$table-border-color: $gray-300;

.table-custom {
	width: 100%;
	max-width: 100%;
	text-align: left;
	background: $table-bg;
	border-collapse: collapse;
	
	th,
	td {
		color: $gray-700;
		background: $table-bg;
	}
	
	th {
		font-size: 14px;
		font-weight: 700;
		letter-spacing: .05em;
		white-space: nowrap;
		border: none;
	}
	
	td {
		padding: $table-cell-padding;
	}
	
	tbody tr:first-child {
		td {
			border-top: 0;
		}
	}
	
	tr td {
		border-bottom: 1px solid $table-border-color;
	}
	
	tfoot {
		td {
			font-weight: 700;
		}
	}
}

* + .table-custom-responsive {
	margin-top: 30px;
}

@include media-breakpoint-up(md) {
	* + .table-custom-responsive {
		margin-top: 40px;
	}
}


// Table Custom styles variants
.table-custom {
	thead th {
		color: $white;
		background: $primary;
		border: 0;
	}
	
	tbody tr:hover td {
		background: #fafafa;
	}
}

.table-custom.table-custom-bordered {
	tr {
		td:first-child {
			border-left: 0;
		}
		
		td:last-child {
			border-right: 0;
		}
	}
	
	td {
		border: 1px solid $table-border-color;
	}
	
	tbody > tr:first-of-type > td {
		border-top: 0;
	}
}

.table-custom.table-custom-striped {
	border-bottom: 1px solid $table-border-color;
	
	tbody tr:nth-of-type(odd) td {
		background: transparent;
	}
	
	tbody tr:nth-of-type(even) td {
		background: $table-bg-accent;
	}
	
	tbody td {
		border: 0;
	}
	
	tfoot td:not(:first-child) {
		border-left: 0;
	}
}

// Table Custom Additional
.table-custom-responsive {
	@include media-breakpoint-down(md) {
		display: block;
		width: 100%;
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057
		
		// Prevent double border on horizontal scroll due to use of `display: block;`
		&.table-bordered {
			border: 0;
		}
	}
}
