/** @section Context Styles */

%context-dark {
	&,
	#{headings()} {
		color: $white;
	}
}

%context-light {
	color: $body-color;

	#{headings()} {
		color: $headings-color;
	}
}

// Contexts
// -------------------------

%context-light {
	.form-input-impressed {
		background: rgba(255, 255, 255, .6);
		&, &:focus {
			box-shadow: none;
		}
	}
	.form-label.focus {
		color: $gray-darker;
	}
	
	.quote-outboxed {
		.quote-body {
			box-shadow: $shadow-area-md;
			background: $white;
			
			&:before {
				color: $gray-lighter;
			}
			
			&:after {
				border-color: $white transparent transparent transparent;
			}
		}
	}
}

%context-dark {
	// Text Styles
	&, .btn-link, .form-label, .form-label-outside.focus,
	.select2-container--bootstrap .select2-selection--single .select2-selection__rendered,
	.select2-container--bootstrap .select2-selection,
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
		color: $white;
	}
	
	.form-label.auto-fill{
		color: $form-input-color;
	}
	
	.countdown-custom {
		.countdown-amount, .countdown-period {
			color: $white;
		}
	}
	
	.responsive-tabs-dashed {
		.resp-tabs-list {
			> li {
				color: $gray-light;
				&.resp-tab-active,
				&:hover {
					color: $white;
				}
				& + li {
					&:before {
						background: $gray-light;
					}
				}
			}
		}
	}
	
	.form-wrap {
		> .form-input {
			color: $white;
		}
		
		> .form-input::-webkit-input-placeholder {
			color: $white;
		}
		
		> .form-input::-moz-placeholder {
			color: $white;
		}
		
		> .form-input:-ms-input-placeholder {
			color: $white;
		}
		
		> .form-input:-moz-placeholder {
			color: $white;
		}
	}
	
	// Button Styles
	.btn-default {
		@include button-variant-custom($white, transparent, $white, $gray-darker, $white, $white);
		
		&.btn-anis-effect {
			&:before, &:after {
				background: $white;
			}
		}
	}
	
	// Owl Carousel
	.owl-carousel {
		.owl-prev,
		.owl-next {
			border-color: $white;
			color: $white;
		}
	}
	
	// Counter (type-1)
	.counter-type-1 {
		.divider {
			background: $white;
		}
	}
	
	.form-input-impressed {
		background: $black;
	}
	
	// Footer (variant 2)
	.contact-info dl {
		dd a, dd a:hover {
			color: $gray-dark;
		}
	}
	
	.thumbnail-classic-caption {
		color: $gray-darker;
	}
	
	.thumbnail-classic-title{
		color: $gray-darker;
	}
	
	// Form Validation
	.has-error .form-validation {
		color: $white;
	}
	
	@include media-breakpoint-up(md) {
		.form-label-outside.auto-fill {
			color: $white;
		}
	}
	
	@include media-breakpoint-up(xl) {
		.isotope-filters.isotope-filter-collapse-lg {
			.isotope-filters-list {
				border-left-color: $gray-dark;
				
				> li > a {
					color: $gray;
					
					&:after {
						background: $white;
					}
					
					&:hover,
					&.active {
						color: $white;
					}
				}
			}
		}
	}
}

.context-light {
	@extend %context-light;
}

.context-dark {
	@extend %context-dark;
}

.bg-black {
	.form-input-impressed {
		background: darken($black, 3%);
	}
}


.bg-accent{
	.btn-java {
		@include button-variant-custom($white, $brand-java, $brand-java, $white, #3b61b9, #3b61b9);
	}
}
