/** @section Countdown */

.countdown {
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1.2;
	text-align: center;
	@include group(15px);
	
	@include media-breakpoint-up(md) {
		@include group(30px);
	}
}

.countdown-block {
	position: relative;
	flex: 1 1 33.33%;
	
	@include media-breakpoint-up(sm) {
		flex: 1 1 25%;
	}
}

.countdown-block-seconds {
	display: none;
	
	@include media-breakpoint-up(sm) {
		display: block;
	}
}

.countdown-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.countdown-circle {
	fill: transparent;
	
	html:not(.ie-11):not(.ie-10) & {
		width: 100%;
		height: auto;
	}
}

.countdown-circle-bg {
	stroke: white;
	stroke-width: 10px;
}

.countdown-circle-fg {
	stroke: #f2e661;
	stroke-width: 10px;
}

.countdown-counter {
	line-height: 1;
	letter-spacing: 0;
}

.countdown-title {
	font-size: 14px;
	line-height: 1.2;
	color: $white;
	font-weight: 700;
	text-transform: capitalize;
	@include media-breakpoint-up(md) {
		font-size: 22px;
		position: absolute;
		bottom: -40px;
		left: 50%;
		transform: translateX(-50%);
	}
}



* + .countdown {
	margin-top: 50px;
}

.countdown + * {
	margin-top: 60px;
	@include media-breakpoint-up(md) {
		margin-top: 80px;
	}
	@include media-breakpoint-up(xl) {
		margin-top: 130px;
	}
}

.countdown-circle-bg {
  stroke-width: 10px;
}

.countdown-circle-fg {
  stroke-width: 10px;
}

.single-page-countdown {
	max-width: 640px;
	margin-right: auto;
	margin-left: auto;
	
	.countdown-counter {
		font-size: 14px;
	}
}